// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$black: rgba(0, 0, 0, 0.87);
$white: rgba(255, 255, 255, 0.87);

$blue-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: white,
    600: white,
    700: white,
    800: $white,
    900: $white,
    A100: $black,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$red-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #e2001a,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: white,
    600: white,
    700: white,
    800: $white,
    900: $white,
    A100: $black,
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$servinext-b2b-web-primary: mat.define-palette($blue-palette, 800);
$servinext-b2b-web-accent: mat.define-palette(
  mat.$orange-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$servinext-b2b-web-warn: mat.define-palette($red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$servinext-b2b-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $servinext-b2b-web-primary,
      accent: $servinext-b2b-web-accent,
      warn: $servinext-b2b-web-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

.primary-text {
  color: mat.get-color-from-palette($servinext-b2b-web-primary);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($servinext-b2b-web-theme);

