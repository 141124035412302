/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Material Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-material.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.ag-theme-material,
.ag-theme-material-dark,
.ag-theme-material-auto-dark {
  --ag-material-accent-color: #009708;
}

html,
body {
  height: 100%;
}
